import { API } from "@/apps/core/api";
import ViewModel from "@/apps/core/models/viewModel";
import { Komponen } from "@/apps/penyakit/models/komponen";
import { AktivitasKlinis } from "./aktivitasKlinis";
import { AktivitasKomponen } from "./aktivitasKomponen";


export class AktivitasTatalaksana extends AktivitasKomponen {
  tatalaksana: Komponen | null = null;

  loadData(json: Record<string, any>) {
    const aktivitasKlinis = new AktivitasKlinis();
    const diagnosis = new Komponen();
    diagnosis.loadData(json.diagnosis);
    const tatalaksana = new Komponen();
    tatalaksana.loadData(json.tatalaksana);
    aktivitasKlinis.loadData(json);
    json.aktivitas = aktivitasKlinis;
    json.tatalaksana = tatalaksana;
    json.diagnosis = diagnosis;
    super.loadData(json);
  }
}

export class AktivitasTatalaksanaAPI extends API {
  static modelPath = "/aktivitas/tatalaksana/";

  constructor() {
    super(AktivitasTatalaksanaAPI.modelPath);
  }
}

export default class AktivitasTatalaksanaVM extends ViewModel {
  constructor() {
    super(new AktivitasTatalaksanaAPI(), new AktivitasTatalaksana());
  }
}
























































































































































import axios from "axios";
import { computed, defineComponent, reactive, ref, watch } from "@vue/composition-api";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
} from "buefy";
import Vue from "vue";
import AktivitasPemeriksaanVM from "@/apps/aktivitas/models/aktivitasPemeriksaan";
import AktivitasTatalaksanaVM from "@/apps/aktivitas/models/aktivitasTatalaksana";
import APP_CONFIG from "@/apps/core/modules/config";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import useViewModel from "@/apps/core/modules/useViewModel";
import AktivitasKeterampilanVM from "../models/aktivitasKeterampilan";
import { setRefreshRequest as setAktivitasRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";
import { setRefreshRequest as setPerawatanRefreshRequest } from "@/apps/perawatan/modules/stores/perawatan";
import { AktivitasKomponen } from "../models/aktivitasKomponen";

const VmGetterMap: Record<string, Function> = {
  pemeriksaan: () => new AktivitasPemeriksaanVM(),
  tatalaksana: () => new AktivitasTatalaksanaVM(),
  keterampilan: () => new AktivitasKeterampilanVM(),
};

const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  setAktivitasRefreshRequest(requestBy, filterMap);
  setPerawatanRefreshRequest(requestBy, filterMap);
};

export default defineComponent({
  name: "AktivitasKomponenDetail",
  props: {
    headerText: { type: String, required: true },
    aktKomp: Object,
    vmRef: Object,
  },
  components: {
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup(props) {
    let aktKomp = props.aktKomp as AktivitasKomponen;
    let vmRef = props.vmRef;
    const paramsId = router.currentRoute.params.id;
    const komponenStr = props.headerText.toLowerCase();
    if (aktKomp === undefined) {
      const vm = VmGetterMap[komponenStr]();
      const { viewModelRef, instance } = useViewModel(paramsId, vm);
      aktKomp = instance as AktivitasKomponen;
      vmRef = viewModelRef;
    }
    const menuConfigs = [
      {
        label: "Ubah",
        onClick: () =>
          router.push({
            name: `aktivitas-${komponenStr}-change`,
            params: { id: paramsId },
          }),
      },
      {
        label: "Hapus",
        onClick: () => {
          Dialog.confirm({
            message: "Hapus aktivitas ini?",
            onConfirm: async () => {
              if (!aktKomp || !vmRef) return;
              await vmRef.api.delete(aktKomp.id);
              setRefreshRequest("context-menu");
              router.go(-1);
            },
          });
        },
      },
    ];

    const fotoDataRef = ref(null);
    const isUploading = ref(false);

    const canChange = computed(
      () => aktKomp && aktKomp.extraData && aktKomp.extraData.canChange
    );

    const canUpload = computed(
      () => aktKomp && aktKomp.extraData && aktKomp.extraData.canUpload
    );

    const uploadPhoto = new UploadPhoto(
      `aktivitas${komponenStr}`,
      (uploading: boolean) => {
        isUploading.value = uploading;
      }
    );

    const saveFoto = async (url: string) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/${komponenStr}/foto/`;
      const data = { 'aktivitas_komponen': paramsId, url: url };
      try {
        const response = await axios.post(uploadDocUrl, data);
        if (aktKomp.fotoSet === null) aktKomp.fotoSet = [];
        const length = aktKomp.fotoSet.length;
        aktKomp.fotoSet.splice(length, 0, {
          id: response.data.id,
          fotokomponenUrl: response.data.url,
        });
        fotoDataRef.value = null;
        Toast.open("Foto berhasil di upload.");
      } catch {
        Toast.open("Foto gagal di upload.");
      }
    };

    const uploadFile = (foto: File) => {
      uploadPhoto.requestAWS(foto, saveFoto);
    };

    const galleryId = ref(`g${new Date().getTime()}`);
    const imageMap = reactive({} as Record<string, boolean>);

    const updateImageMap = (event: Event, imgId: string) => {
      const el = event.target as HTMLInputElement;
      imageMap[imgId] = el.checked;
    };
    const deleteImages = async () => {
      // api call untuk hapus gambar
      if (!aktKomp || !aktKomp.fotoSet) return;
      const url = `${APP_CONFIG.baseAPIURL}/aktivitas/${komponenStr}/foto/delete/`;
      try {
        const docs = aktKomp.fotoSet.filter((foto) => {
          return imageMap[foto.id];
        });
        const pkArr = docs.map((foto) => foto.id);
        const resp: Record<string, any> = await axios.delete(url, {
          data: { 'pk_list': pkArr },
        });
        const deletedDocs = resp.data;
        Toast.open("Data berhasil dihapus.");
        aktKomp.fotoSet = aktKomp.fotoSet.filter((foto) => {
          return !deletedDocs.includes(foto.id);
        });
        for (const docId of deletedDocs) {
          delete imageMap[docId];
        }
      } catch (error) {
        const errorMessage = "Gagal menghapus data.";
        Toast.open(errorMessage);
        throw new Error(errorMessage);
      }
      for (const docId of Object.keys(imageMap)) {
        imageMap[docId] = false;
      }
      galleryId.value = `g${new Date().getTime()}`;
    };

    // TODO: cek kembali
    // berlaku untuk diagnosis dan keterampilan, karena memiliki template sendiri
    if (aktKomp.fotoSet) {
      for (const foto of aktKomp.fotoSet) {
        Vue.set(imageMap, foto.id, false);
      }
    }

    // TODO: cek kembali
    // berlaku untuk pemeriksaan dan tatalaksana
    watch(aktKomp, () => {
      if (aktKomp.fotoSet) {
        for (const foto of aktKomp.fotoSet) {
          Vue.set(imageMap, foto.id, false);
        }
      }
    });

    return {
      // Data
      aktKomponen: aktKomp,
      menuConfigs,
      viewModelRef: vmRef,
      fotoDataRef,
      galleryId,
      imageMap,
      isUploading,

      // Computed
      canChange,
      canUpload,

      // Method
      deleteImages,
      uploadFile,
      toUserDateFormat,
      updateImageMap,
    };
  },
});

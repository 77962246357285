import { API } from "@/apps/core/api";
import ViewModel from "@/apps/core/models/viewModel";
import { Komponen } from "@/apps/penyakit/models/komponen";
import { AktivitasKlinis } from "./aktivitasKlinis";
import { AktivitasKomponen } from "./aktivitasKomponen";


export class AktivitasPemeriksaan extends AktivitasKomponen {
  pemeriksaan: Komponen | null = null;

  loadData(json: Record<string, any>) {
    const aktivitasKlinis = new AktivitasKlinis();
    const diagnosis = new Komponen();
    diagnosis.loadData(json.diagnosis);
    const pemeriksaan = new Komponen();
    pemeriksaan.loadData(json.pemeriksaan);
    aktivitasKlinis.loadData(json);
    json.aktivitas = aktivitasKlinis;
    json.pemeriksaan = pemeriksaan;
    json.diagnosis = diagnosis;
    super.loadData(json);
  }
}

export class AktivitasPemeriksaanAPI extends API {
  static modelPath = "/aktivitas/pemeriksaan/";

  constructor() {
    super(AktivitasPemeriksaanAPI.modelPath);
  }
}

export default class AktivitasPemeriksaanVM extends ViewModel {
  constructor() {
    super(new AktivitasPemeriksaanAPI(), new AktivitasPemeriksaan());
  }
}
